import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { config, shared } from "../../styles";
import { Client } from "@pentacode/core/src/client";
import { AjaxSender } from "../../lib/ajax";
import { WebCryptoProvider } from "../../lib/crypto";
import { Employee, employmentTypeLabel, JobPosting } from "@pentacode/core/src/model";
import { alert } from "../alert-dialog";
import { formatDate, formatNumber, parseDateString, toDateString, toTimeString } from "@pentacode/core/src/util";
import { Avatar } from "./../avatar";
import { CreateJobApplicationParams } from "@pentacode/core/src/api";
import "../rich-content";
import { singleton } from "../../lib/singleton";
import { ImagesDialog } from "./images-dialog";
import { Textarea } from "../textarea";
import { DateString } from "@pentacode/openapi";

@customElement("ptc-jobs-app")
export class JobsApp extends LitElement {
    readonly routePattern = /^\/postings\/(?<uuid>[^/]+)/;

    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @state()
    private _posting: JobPosting | null = null;

    @state()
    private _loading = false;

    @state()
    private _editingAvailableFrom = false;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='firstName']")
    private _firstNameInput: HTMLInputElement;

    @query("ptc-avatar")
    private _avatar: Avatar;

    @singleton("ptc-jobs-images-dialog")
    private _imagesDialog: ImagesDialog;

    private _api = new Client(new AjaxSender(process.env.PTC_SERVER_URL!), new WebCryptoProvider());

    constructor() {
        super();
        // load in the background
        void this.load();
    }

    async load() {
        const uuid = window.location.pathname.match(this.routePattern)?.groups?.uuid;

        if (uuid) {
            this._loading = true;

            try {
                this._posting = await this._api.getJobPosting(uuid);
                document.title = `Mitarbeiter gesucht: ${this._posting.title} | Pentacode Jobs`;
            } catch (e) {
                void alert(e.message, { type: "warning" });
            }

            this._loading = false;
        }

        const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
        loadingScreen && (loadingScreen.style.display = "none");
    }

    private async _submit(e: Event) {
        e.preventDefault();

        const data = new FormData(this._form);
        const email = data.get("email") as string;
        const firstName = data.get("firstName") as string;
        const lastName = data.get("lastName") as string;
        const birthday = (data.get("birthday") as DateString) || null;
        const availableFrom = (data.get("availableFrom") as DateString) || null;
        const address = data.get("address") as string;
        const phone = data.get("phone") as string;
        const postalCode = data.get("postalCode") as string;
        const city = data.get("city") as string;
        const message = data.get("message") as string;
        const headShot = this._avatar.newImage;

        const today = toDateString(new Date());

        // If birthday is input, it must be a valid date and between 1900-01-01 and today
        if (!birthday || !parseDateString(birthday) || birthday < "1900-01-01" || birthday > today) {
            const input = this._form.querySelector("input[name='birthday']") as HTMLInputElement;
            input.setCustomValidity("Bitte gib ein gültiges Geburtsdatum ein.");
            this._form.reportValidity();
            return;
        }

        // If availableFrom is input, it must be a valid date, today or later
        if (availableFrom && (!parseDateString(availableFrom) || availableFrom < today)) {
            const input = this._form.querySelector("input[name='availableFrom']") as HTMLInputElement;
            input.setCustomValidity("Bitte gib ein gültiges Verfügbarkeitsdatum ein.");
            this._form.reportValidity();
            return;
        }

        this._loading = true;

        try {
            await this._api.createJobApplication(
                new CreateJobApplicationParams({
                    postingUuid: this._posting!.uuid,
                    email,
                    firstName,
                    lastName,
                    birthday,
                    availableFrom,
                    address,
                    postalCode,
                    city,
                    phone,
                    headShot,
                    message,
                })
            );
            await alert("Deine Bewerbung wurde erfolgreich übermittelt. Viel Erfolg! 🤞", {
                icon: "paper-plane",
                type: "success",
                title: "Bewerbung Abgeschickt",
            });
            window.location.reload();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        config,
        shared,
        Textarea.styles,
        css`
            h1 {
                font-size: 2em;
            }

            .sidebar {
                width: 20em;
                position: sticky;
                top: 0;
                margin-top: 7em;
            }

            .venue-location {
                width: 100%;
                border-radius: 0.5em;
                box-shadow: rgb(0 0 0 / 30%) 0 1px 3px;
                margin-bottom: 0.5em;
            }

            .images {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.5em;
                margin-bottom: 1em;
            }

            .image-container {
                position: relative;
                padding-bottom: 75%;
                cursor: pointer;
            }

            .title-image {
                padding-bottom: 50%;
                margin-bottom: 1.5em;
            }

            .image-container img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                border-radius: 0.5em;
                box-shadow: rgb(0 0 0 / 30%) 0 1px 3px;
            }

            .inline-details {
                margin: 2em 0;
            }

            @media (max-width: 800px) {
                .side-details {
                    display: none;
                }

                h1 {
                    margin-top: 0;
                }
            }

            @media (min-width: 801px) {
                .inline-details {
                    display: none;
                }
            }
        `,
    ];

    private _renderDetails({
        employmentType,
        payAmount,
        payType,
        venue,
        start,
        end,
        hoursPerWeek,
        images,
    }: JobPosting) {
        return html`
            <div class="margined blue semibold colored-text">Beschäftigungsverhältnis</div>

            <div class="margined">${employmentTypeLabel(employmentType)}</div>

            <div class="horizontal evenly stretching layout">
                <div>
                    <div class="margined blue semibold colored-text">Beginn</div>

                    <div class="margined">${start ? formatDate(start) : "Ab Sofort"}</div>
                </div>

                <div>
                    <div class="margined blue semibold colored-text">Ende</div>

                    <div class="margined">${end ? formatDate(end) : "Unbegrenzt"}</div>
                </div>
            </div>

            <div class="horizontal evenly stretching layout">
                <div>
                    <div class="margined blue semibold colored-text">Arbeitszeit</div>

                    <div class="margined">
                        ${hoursPerWeek ? `${formatNumber(hoursPerWeek, 0)} St. / Woche` : "Keine Angabe"}
                    </div>
                </div>

                <div>
                    <div class="margined blue semibold colored-text">Bezahlung</div>

                    <div class="margined">
                        ${payAmount
                            ? `${formatNumber(payAmount)} € / ${payType === "monthly" ? "Monat" : "Stunde"}`
                            : "Keine Angabe"}
                    </div>
                </div>
            </div>

            <div class="margined blue semibold colored-text">Arbeitsort</div>

            <div class="margined">
                <strong>${venue.name}</strong> <br />
                ${venue.address} <br />
                ${venue.postalCode} ${venue.city}
            </div>

            <a
                href="https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    `${venue.name},${venue.address},${venue.postalCode} ${venue.city},${venue.state}`
                )}"
            >
                <img
                    class="venue-location"
                    src="https://maps.googleapis.com/maps/api/staticmap?markers=${encodeURIComponent(
                        `${venue.name},${venue.address},${venue.postalCode} ${venue.city},${venue.state}`
                    )}&size=560x280&scale=2&key=AIzaSyDce14aKN0ocoT4g9fQTdWD2ib7rtdg648"
                />
            </a>

            <div>
                <div class="margined blue semibold colored-text">Eindrücke</div>
                <div class="images">
                    ${images.map(
                        (img, index) =>
                            html` <div
                                class="image-container"
                                @click=${() => this._imagesDialog.show({ images, index })}
                            >
                                <img src="${img}" />
                            </div>`
                    )}
                </div>
            </div>
        `;
    }

    private _renderPosting(posting: JobPosting) {
        const { title, description, images } = posting;
        return html`
            <div class="padded vertical layout" style="width: 100%; max-width: 60em;">
                <div class="padded spacing horizontal layout">
                    <div class="padded stretch">
                        <h1>
                            <div class="subtle small semibold">Mitarbeiter gesucht:</div>
                            <div class="blue colored-text">${title}</div>
                        </h1>

                        ${images.length
                            ? html`
                                  <div
                                      class="image-container title-image"
                                      @click=${() => this._imagesDialog.show({ images, index: 0 })}
                                  >
                                      <img src=${images[0]} />
                                  </div>
                              `
                            : ""}

                        <ptc-rich-content class="margined" .content=${description}></ptc-rich-content>

                        <div class="inline-details">${this._renderDetails(posting)}</div>

                        <div style="max-width: 40em">
                            <h2>Jetzt Bewerben:</h2>

                            <form @submit=${this._submit}>
                                <div class="horizontal center-aligning spacing layout">
                                    <ptc-avatar class="enormous" .employee=${new Employee()} editable></ptc-avatar>
                                    <div class="stretch">
                                        <div class="field">
                                            <label>Vorname</label>
                                            <input name="firstName" required />
                                        </div>
                                        <div class="field">
                                            <label>Nachname</label>
                                            <input name="lastName" required />
                                        </div>
                                    </div>
                                </div>
                                <div class="horizontal spacing evenly stretching layout">
                                    <div class="field">
                                        <label> Geburtstag </label>
                                        <input
                                            name="birthday"
                                            type="date"
                                            @change=${(e: Event) => {
                                                const input = e.target as HTMLInputElement;
                                                input.setCustomValidity("");
                                            }}
                                        />
                                    </div>
                                    <div class="field">
                                        <label> Verfügbar Ab </label>
                                        ${this._editingAvailableFrom
                                            ? html`
                                                  <input
                                                      name="availableFrom"
                                                      type="date"
                                                      @change=${(e: Event) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.setCustomValidity("");
                                                      }}
                                                  />
                                              `
                                            : html`
                                                  <div class="horizontal half-padded layout">
                                                      <div class="subtle bold padded">Sofort</div>
                                                      <button
                                                          class="slim transparent"
                                                          @click=${() => (this._editingAvailableFrom = true)}
                                                      >
                                                          <i class="pencil"></i>
                                                      </button>
                                                  </div>
                                              `}
                                    </div>
                                </div>
                                <div class="horizontal spacing evenly stretching layout">
                                    <div class="field">
                                        <label> Email </label>
                                        <input name="email" type="email" required />
                                    </div>
                                    <div class="field">
                                        <label> Telefon </label>
                                        <input name="phone" type="tel" />
                                    </div>
                                </div>
                                <div class="field">
                                    <label> Adresse </label>
                                    <input name="address" required />
                                </div>
                                <div class="horizontal spacing evenly stretching layout">
                                    <div class="field">
                                        <label> Postleitzahl </label>
                                        <input name="postalCode" required />
                                    </div>
                                    <div class="field">
                                        <label> Stadt </label>
                                        <input name="city" required />
                                    </div>
                                </div>
                                <div class="field">
                                    <label>Nachricht</label>
                                    <ptc-textarea
                                        name="message"
                                        placeholder="Erzähle etwas über deine Person oder beantworte in der Ausschreibung gestellte Fragen."
                                        maxLength="500"
                                    ></ptc-textarea>
                                </div>
                                <div class="horizontal top-margined spacing evenly stretching layout">
                                    <button class="primary"><i class="paper-plane"></i> Absenden</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="side-details">
                        <div class="padded sidebar">
                            ${this._renderDetails(posting)}

                            <div class="padded vertical layout">
                                <button class="primary" @click=${() => this._firstNameInput?.focus()}>
                                    Jetzt Bewerben
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        return html`
            <div class="centering layout">${this._posting ? this._renderPosting(this._posting) : ""}</div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
